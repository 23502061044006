// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-epikoinonia-js": () => import("./../../../src/pages/epikoinonia.js" /* webpackChunkName: "component---src-pages-epikoinonia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ta-nea-mas-js": () => import("./../../../src/pages/ta-nea-mas.js" /* webpackChunkName: "component---src-pages-ta-nea-mas-js" */),
  "component---src-pages-to-spitiko-js": () => import("./../../../src/pages/to-spitiko.js" /* webpackChunkName: "component---src-pages-to-spitiko-js" */),
  "component---src-pages-συσκευασμενα-κρυα-σαντουιτς-js": () => import("./../../../src/pages/συσκευασμενα-κρυα-σαντουιτς.js" /* webpackChunkName: "component---src-pages-συσκευασμενα-κρυα-σαντουιτς-js" */),
  "component---src-pages-χειροποιητες-μπαρες-δημητριακων-js": () => import("./../../../src/pages/χειροποιητες-μπαρες-δημητριακων.js" /* webpackChunkName: "component---src-pages-χειροποιητες-μπαρες-δημητριακων-js" */),
  "component---src-templates-bars-js": () => import("./../../../src/templates/bars.js" /* webpackChunkName: "component---src-templates-bars-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-sandwiches-js": () => import("./../../../src/templates/sandwiches.js" /* webpackChunkName: "component---src-templates-sandwiches-js" */)
}

